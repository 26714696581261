import {
    currentCrumb,
    extractSelectedLanguage,
    itemSalesUrl,
    linkCrumb,
    LivligButton,
    LoadingIndicator,
    NavigationList,
    Segment,
} from "@ingka-livlig/frontend-lib";
import React from "react";
import useApiRequest from "./useApiRequest";
import styles from "./MetricsSellingUnit.module.css";
import { useParams } from "react-router-dom";
import MetricsPage from "./MetricsPage";
import { PageViewGraph } from "./PageViewGraph";
import { Trans } from "@lingui/react/macro";

export const MetricsSellingUnit: React.FC = () => {
    const { locationId } = useParams<{
        locationId: string;
    }>();

    const locationType = "SellingUnit";
    const locale = extractSelectedLanguage();

    const metricsQueryStore = useApiRequest("metricsQuery", locationType, locationId ?? "");

    function renderContent() {
        switch (metricsQueryStore.tag) {
            case "loading":
                return <LoadingIndicator />;
            case "error":
                return (
                    <p className={styles.metric_message}>
                        <Trans>Failed to fetch the Metrics Data</Trans>
                    </p>
                );
            case "success":
                const data = metricsQueryStore.value.sellingUnitPageViewsByDate;
                const hfbName = metricsQueryStore.value.hfbScopes;

                const renderButton = (selection: string) => {
                    return (
                        <nav className={styles.metrics_nav}>
                            <LivligButton type={"secondary"} href={`/locations/SellingUnit/${locationId}/${selection}`}>
                                <Trans>View all</Trans>
                            </LivligButton>
                        </nav>
                    );
                };
                return (
                    <React.Fragment>
                        <div className={styles.button_sales}>
                            <LivligButton
                                type={"primary"}
                                href={`${itemSalesUrl()}/locations/SellingUnit/${locationId}`}
                            >
                                <Trans>View sales</Trans>
                            </LivligButton>
                        </div>
                        <div className={styles.metricsInfo}>
                            <div className={styles.mainTitle}>{metricsQueryStore.value.sellingUnitScope?.name}</div>
                            <p>
                                <Trans>Page views last 35 days</Trans>
                            </p>
                            <PageViewGraph data={data} />
                        </div>

                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Roles</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>
                            {metricsQueryStore.value.rolePageViews.slice(0, 3).map(({ role, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <div className={styles.storeView}>{role}</div>
                                        <span className={styles.pageView}>{pageView}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("role")}
                        </div>

                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Most viewed pages</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            <div>
                                {metricsQueryStore.value.featurePageViews
                                    .slice(0, 3)
                                    .map(({ feature, pageView }, index) => (
                                        <NavigationList key={index}>
                                            <div className={styles.entry}>
                                                <div className={styles.indexSmall}>{index + 1}</div>
                                                <span className={styles.storeView}>{feature}</span>
                                                <span className={styles.pageView}>
                                                    {pageView.toLocaleString(locale)}
                                                </span>
                                            </div>
                                        </NavigationList>
                                    ))}
                                {renderButton("feature")}
                            </div>
                        </div>
                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Most viewed HFB</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            <div>
                                {metricsQueryStore.value.hfbPageViews
                                    .slice(0, 3)
                                    .map(({ rangeId, pageView }, index) => (
                                        <NavigationList key={index}>
                                            <div className={styles.entry}>
                                                <div className={styles.indexSmall}>{index + 1}</div>
                                                <div className={styles.storeView}>
                                                    {hfbName.find((el) => el.id === rangeId)?.name}
                                                    <div>HFB {rangeId}</div>
                                                </div>
                                                <span className={styles.pageView}>
                                                    {pageView.toLocaleString(locale)}
                                                </span>
                                            </div>
                                        </NavigationList>
                                    ))}
                            </div>
                            {renderButton("HFB")}
                        </div>
                    </React.Fragment>
                );
        }
    }

    return (
        <MetricsPage
            breadcrumbItems={
                metricsQueryStore.tag === "success"
                    ? [
                          linkCrumb("Global", "/locations/global"),
                          linkCrumb(
                              `${metricsQueryStore.value.sellingUnitScope?.countryScope.name}`,
                              `/locations/Country/${metricsQueryStore.value.sellingUnitScope?.countryScope.countryCode}`,
                          ),
                          currentCrumb(`${metricsQueryStore.value.sellingUnitScope?.name}`),
                      ]
                    : []
            }
        >
            <Segment variant="primary" padded>
                {renderContent()}
            </Segment>
        </MetricsPage>
    );
};
