import React from "react";
import {
    currentCrumb,
    extractSelectedLanguage,
    itemSalesUrl,
    linkCrumb,
    LivligButton,
    LoadingIndicator,
    NavigationList,
    NavigationListEntry,
    Segment,
} from "@ingka-livlig/frontend-lib";

import MetricsPage from "./MetricsPage";
import useApiRequest from "./useApiRequest";
import styles from "./Global.module.css";
import { Link, useParams } from "react-router-dom";
import { PageViewGraph } from "./PageViewGraph";
import { Trans } from "@lingui/react/macro";
import { LocationType } from "./api";

export const Global: React.FC = () => {
    const { locationType } = useParams<{
        locationType: LocationType;
    }>();

    const globalMetrics = useApiRequest("globalMetricsQuery", locationType ?? "Global");
    const locale = extractSelectedLanguage();

    const crumbs = () => {
        if (globalMetrics.tag == "success") {
            switch (locationType) {
                case "GlobalOnly":
                    return [linkCrumb(`Global`, `/locations/global`), currentCrumb(`GlobalOnly`)];
                default:
                    return [linkCrumb(`Global`, `/locations/global`)];
            }
        }
    };

    const renderButton = (selection: string) => {
        switch (locationType) {
            case "GlobalOnly":
                return (
                    <nav className={styles.metrics_nav}>
                        <LivligButton type={"secondary"} href={`/locations/GlobalOnly/${selection}`}>
                            <Trans>View all</Trans>
                        </LivligButton>
                    </nav>
                );
            default:
                return (
                    <nav className={styles.metrics_nav}>
                        <LivligButton type={"secondary"} href={`/locations/Global/${selection}`}>
                            <Trans>View all</Trans>
                        </LivligButton>
                    </nav>
                );
        }
    };

    function renderContent() {
        switch (globalMetrics.tag) {
            case "loading":
                return <LoadingIndicator />;
            case "error":
                return (
                    <p className={styles.metric_message}>
                        <Trans>Failed to fetch the Metrics Data</Trans>
                    </p>
                );
            case "success":
                const pageViews = globalMetrics.value.pageViews;
                const pageViewsByDate = globalMetrics.value.pageViewsByDate;
                const roleActivePageViews = globalMetrics.value.roleActivePageViews;
                const hfbScopes = globalMetrics.value.hfbScopes;
                const hfbPageViews = globalMetrics.value.hfbPageViews;
                const featurePageViews = globalMetrics.value.featurePageViews;

                return (
                    <React.Fragment>
                        <div className={styles.button_sales}>
                            <LivligButton type={"primary"} href={`${itemSalesUrl()}/locations/Global`}>
                                <Trans>View sales</Trans>
                            </LivligButton>
                        </div>
                        <div className={styles.metricsInfo}>
                            <div className={styles.mainTitle}>
                                {locationType === "GlobalOnly" && <Trans>Only Global</Trans>}
                                {locationType != "GlobalOnly" && <Trans>Global</Trans>}
                            </div>
                            <p>
                                <Trans>Page views last 35 days</Trans>
                            </p>
                            <PageViewGraph data={pageViewsByDate} />
                        </div>
                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Roles</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>
                            {roleActivePageViews.slice(0, 3).map(({ role, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <span className={styles.countryView}>{role}</span>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("role")}
                        </div>
                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Most viewed pages</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            {featurePageViews.slice(0, 3).map(({ feature, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <span className={styles.countryView}>{feature}</span>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("feature")}
                        </div>
                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Most viewed HFB</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            {hfbPageViews.slice(0, 3).map(({ rangeId, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <div className={styles.countryView}>
                                            {hfbScopes.find((el) => el.id === rangeId)?.name}
                                            <div>HFB {rangeId}</div>
                                        </div>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("HFB")}
                        </div>
                        <div>
                            {pageViews.length != 0 && (
                                <React.Fragment>
                                    <div className={styles.sectionHeading}>
                                        <h2>
                                            <Trans>Countries</Trans>
                                        </h2>
                                        <div>
                                            <Trans>Page views</Trans>
                                        </div>
                                    </div>

                                    {pageViews.map(({ countryName, locationId, pageView }, index) => {
                                        if (countryName == "Only Global" && locationId == "Global") {
                                            return (
                                                <NavigationListEntry key={index}>
                                                    <Link
                                                        to={`/locations/GlobalOnly`}
                                                        className={styles.link}
                                                        preventScrollReset={true}
                                                    >
                                                        <div className={styles.entry}>
                                                            <span className={styles.countryView}>
                                                                {countryName.replace(/\B(\w)/g, (s) => s.toLowerCase())}
                                                            </span>
                                                            <span className={styles.pageView}>
                                                                {pageView.toLocaleString(locale)}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                </NavigationListEntry>
                                            );
                                        } else {
                                            return (
                                                <NavigationListEntry key={index}>
                                                    <Link
                                                        to={`/locations/Country/${locationId}`}
                                                        className={styles.link}
                                                        preventScrollReset={true}
                                                    >
                                                        <div className={styles.entry}>
                                                            <span className={styles.countryView}>
                                                                {countryName.replace(/\B(\w)/g, (s) => s.toLowerCase())}
                                                            </span>
                                                            <span className={styles.pageView}>
                                                                {pageView.toLocaleString(locale)}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                </NavigationListEntry>
                                            );
                                        }
                                    })}
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                );
        }
    }

    return (
        <MetricsPage breadcrumbItems={crumbs()}>
            <Segment variant="primary" padded>
                {renderContent()}
            </Segment>
        </MetricsPage>
    );
};
