import React from "react";

import { NotFound as NF, Page, PageHeader } from "@ingka-livlig/frontend-lib";
import { useLingui } from "@lingui/react/macro";

export default function NotFound() {
    const { t } = useLingui();

    const title = t`Sales`;

    return (
        <Page title={title} headerElement={<PageHeader headerText={t`Metrics`} />}>
            <NF />
        </Page>
    );
}
