import {
    currentCrumb,
    extractSelectedLanguage,
    itemSalesUrl,
    linkCrumb,
    LivligButton,
    LoadingIndicator,
    NavigationList,
    NavigationListEntry,
    Segment,
} from "@ingka-livlig/frontend-lib";
import React from "react";
import useApiRequest from "./useApiRequest";
import styles from "./MetricsCountry.module.css";
import { Link, useParams } from "react-router-dom";
import MetricsPage from "./MetricsPage";
import { PageViewGraph } from "./PageViewGraph";
import { Trans } from "@lingui/react/macro";
import { LocationType } from "./api";
import NotFound from "./NotFound";

export const MetricsCountry: React.FC = () => {
    const { locationType, locationId } = useParams<{
        locationType: LocationType;
        locationId: string;
    }>();

    if (!locationType || !/^(Country|CountryOnly)$/.test(locationType)) return <NotFound />;

    const locale = extractSelectedLanguage();

    const metricsQueryCountry = useApiRequest("metricsQuery", locationType, locationId ?? "");

    const crumbs = () => {
        if (metricsQueryCountry.tag == "success") {
            switch (locationType) {
                case "Country":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(`${metricsQueryCountry.value.countryName}`, `/locations/Country/${locationId}/`),
                        currentCrumb(`Country`),
                    ];
                case "CountryOnly":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(`${metricsQueryCountry.value.countryName}`, `/locations/Country/${locationId}/`),
                        currentCrumb(`CountryOnly`),
                    ];
            }
        }
    };

    function renderContent() {
        switch (metricsQueryCountry.tag) {
            case "loading":
                return <LoadingIndicator />;
            case "error":
                return (
                    <p className={styles.metric_message}>
                        <Trans>Failed to fetch the Metrics Data</Trans>
                    </p>
                );
            case "success":
                const countryTotalPageViewsByDate = metricsQueryCountry.value.countryTotalPageViewsByDate;
                const hfbScopes = metricsQueryCountry.value.hfbScopes;
                const rolePageViews = metricsQueryCountry.value.rolePageViews;
                const featurePageViews = metricsQueryCountry.value.featurePageViews;
                const hfbPageViews = metricsQueryCountry.value.hfbPageViews;
                const countryPageViews = metricsQueryCountry.value.countryPageViews;
                const locationIdParam = locationId;

                const renderButton = (selection: string) => {
                    return (
                        <nav className={styles.metrics_nav}>
                            <LivligButton
                                type={"secondary"}
                                href={`/locations/${locationType}/${locationId}/${selection}`}
                            >
                                <Trans>View all</Trans>
                            </LivligButton>
                        </nav>
                    );
                };

                return (
                    <React.Fragment>
                        <div className={styles.button_sales}>
                            <LivligButton type={"primary"} href={`${itemSalesUrl()}/locations/Country/${locationId}`}>
                                <Trans>View sales</Trans>
                            </LivligButton>
                        </div>

                        <div className={styles.metricsInfo}>
                            <div className={styles.mainTitle}>
                                {locationType === "CountryOnly" && (
                                    <Trans>Only {metricsQueryCountry.value.countryName}</Trans>
                                )}
                                {locationType === "Country" && <Trans>{metricsQueryCountry.value.countryName}</Trans>}
                            </div>
                            <p>
                                <Trans>Page views last 35 days</Trans>
                            </p>
                            <PageViewGraph data={countryTotalPageViewsByDate} />
                        </div>

                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Roles</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            {rolePageViews.slice(0, 3).map(({ role, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <span className={styles.countryView}>{role}</span>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("role")}
                        </div>

                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Most viewed pages</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            {featurePageViews.slice(0, 3).map(({ feature, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <span className={styles.countryView}>{feature}</span>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("feature")}
                        </div>
                        <div>
                            <div className={styles.sectionHeading}>
                                <h2>
                                    <Trans>Most viewed HFB</Trans>
                                </h2>
                                <div>
                                    <Trans>Page views</Trans>
                                </div>
                            </div>

                            {hfbPageViews.slice(0, 3).map(({ rangeId, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <div className={styles.storeView}>
                                            {hfbScopes.find((el) => el.id === rangeId)?.name}
                                            <div>HFB {rangeId}</div>
                                        </div>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                            {renderButton("HFB")}
                        </div>

                        <div>
                            {countryPageViews.length != 0 && (
                                <React.Fragment>
                                    <div className={styles.sectionHeading}>
                                        <h2>
                                            <Trans>Stores</Trans>
                                        </h2>
                                        <div>
                                            <Trans>Page views</Trans>
                                        </div>
                                    </div>

                                    {countryPageViews.map(
                                        ({ locationId, storeName, pageView, locationType }, index) => {
                                            if (locationIdParam === locationId && locationType == "Country") {
                                                return (
                                                    <NavigationListEntry key={index}>
                                                        <Link
                                                            to={`/locations/CountryOnly/${locationId}`}
                                                            className={styles.link}
                                                            preventScrollReset={true}
                                                        >
                                                            <div className={styles.entry}>
                                                                <div className={styles.indexSmall}>{index + 1}</div>
                                                                <div className={styles.storeView}>
                                                                    <div>{storeName}</div>
                                                                    <span>{locationId}</span>
                                                                </div>
                                                                <span className={styles.pageView}>
                                                                    {pageView.toLocaleString(locale)}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    </NavigationListEntry>
                                                );
                                            } else {
                                                return (
                                                    <NavigationListEntry key={index}>
                                                        <Link
                                                            to={`/locations/${locationType}/${locationId}`}
                                                            className={styles.link}
                                                            preventScrollReset={true}
                                                        >
                                                            <div className={styles.entry}>
                                                                <div className={styles.indexSmall}>{index + 1}</div>
                                                                <div className={styles.storeView}>
                                                                    <div>{storeName}</div>
                                                                    <span>{locationId}</span>
                                                                </div>
                                                                <span className={styles.pageView}>
                                                                    {pageView.toLocaleString(locale)}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    </NavigationListEntry>
                                                );
                                            }
                                        },
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                );
        }
    }

    return (
        <MetricsPage breadcrumbItems={crumbs()}>
            <Segment variant="primary" padded>
                {renderContent()}
            </Segment>
        </MetricsPage>
    );
};
